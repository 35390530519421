import { withStyles } from '@material-ui/styles';
import React from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

const styles = theme => ({
    base: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    variantSuccess: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    variantError: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantWarning: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantInfo: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
});


const SnackbarProvider = ({ classes, ...other }) => (
    <NotistackSnackbarProvider
        maxSnack={2}
        classes={classes}
        {...other}
    />
);

export default withStyles(styles)(SnackbarProvider);
