
export const SERVICES_USERS_ON_PATCHED = 'SERVICES_USERS_ON_PATCHED';

export const ON_LOGIN_SUCCESS = 'auth.ON_LOGIN_SUCCESS';
export const ON_LOGOUT = 'auth.ON_LOGOUT';

/*
 * action creators
 */

export const login = user => ({
    type: ON_LOGIN_SUCCESS,
    user,
});

export const logout = () => ({
    type: ON_LOGOUT,
});

export const saveUser = (services, user) => (
    dispatch => (user._id
        ? dispatch(services.users.patch(user._id, user))
        : dispatch(services.users.create(user))
    )
);
