
export const loaderSelector = (state, key) => (
    state.loading[key] || { isLoading: false, error: null }
);

export const isLoadingSelector = (state, key) => loaderSelector(state, key).isLoading;

export const loadingErrorSelector = (state, key) => loaderSelector(state, key).error;

export const isInitializedSelector = state => !isLoadingSelector(state, 'app');
