import * as Yup from 'yup';
import { notificationMethods, NOTIFY_VIA_SMS } from '../reminders/config';

export const loginSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required'),
});

export const settingsSchema = Yup.object().shape({
    notifyVia: Yup.string().nullable().oneOf(notificationMethods),
    phone: Yup.string().when('notifyVia', {
        is: NOTIFY_VIA_SMS,
        then: Yup.string().required(),
    }),
    email: Yup.string().email().required('Required'),
});
