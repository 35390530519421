import shortid from 'shortid';

export const NOTIFY_VIA_EMAIL = 'email';
export const NOTIFY_VIA_SMS = 'sms';

export const notificationMethods = [
    NOTIFY_VIA_EMAIL, NOTIFY_VIA_SMS,
];

export const notificationMethodChoices = [
    { value: NOTIFY_VIA_EMAIL, label: 'E-Mail' },
    { value: NOTIFY_VIA_SMS, label: 'SMS' },
];

export const RECURRENCE_ONCE = 'once';
export const RECURRENCE_DAILY = 'daily';
export const RECURRENCE_WEEKLY = 'weekly';
export const RECURRENCE_MONTHLY = 'monthly';
export const RECURRENCE_QUARTERLY = 'quarterly';
export const RECURRENCE_YEARLY = 'yearly';

export const recurrenceTypes = [
    RECURRENCE_ONCE, RECURRENCE_DAILY, RECURRENCE_WEEKLY,
    RECURRENCE_MONTHLY, RECURRENCE_QUARTERLY, RECURRENCE_YEARLY,
];

export const recurrenceTypeChoices = [
    { value: RECURRENCE_ONCE, label: 'Once' },
    { value: RECURRENCE_DAILY, label: 'Daily' },
    { value: RECURRENCE_WEEKLY, label: 'Weekly' },
    { value: RECURRENCE_MONTHLY, label: 'Monthly' },
    { value: RECURRENCE_QUARTERLY, label: 'Quarterly' },
    { value: RECURRENCE_YEARLY, label: 'Yearly' },
];

export const getBlankReminder = () => ({
    _tempId: shortid.generate(),
    title: '',
    date: null,
    isEnabled: true,
    notifyVia: null,
    recurrence: RECURRENCE_ONCE,
    isVisible: true,
});
