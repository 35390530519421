import * as Yup from 'yup';
import { notificationMethods, recurrenceTypes } from './config';

export const reminderSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    date: Yup.date().required('Required').typeError('Required'),
    isEnabled: Yup.boolean().required('Required'),
    notifyVia: Yup.string().nullable().oneOf([...notificationMethods, null]),
    recurrence: Yup.string().oneOf(recurrenceTypes),
});
