import Grid from '@material-ui/core/Grid';
import React from 'react';
import { bool, func, shape } from 'prop-types';
import {
    Divider,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanelActions,
    ExpansionPanel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik, Form } from 'formik';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';

import { setLoading, setLoadingError } from '../../modules/loading/actions';
import { ErrorPropType } from '../../modules/loading/proptypes';
import { isLoadingSelector, loadingErrorSelector } from '../../modules/loading/selectors';
import { getReminderGroupId } from '../../modules/reminders/helpers';
import { ReminderPropType } from '../../modules/reminders/proptypes';
import ErrorMessage from '../basic/ErrorMessage';
import LoadingButton from '../basic/LoadingButton';
import ReminderFormFields from './ReminderFormFields';
import { reminderSchema } from '../../modules/reminders/schema';
import ReminderSummary from './ReminderSummary';

const ReminderForm = ({
    reminder, isExpanded, onExpand, onSave, onDelete, onHide, enqueueSnackbar,
    error, setError, isDeleteLoading, setDeleteLoading, style,
}) => (
    <Formik
        initialValues={reminder}
        validationSchema={reminderSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => (
            onSave(values)
                .then((savedReminder) => {
                    if (getReminderGroupId(reminder) === getReminderGroupId(savedReminder)) {
                        /*
                         * Only call `resetForm` if the groupId is the same.
                         * Otherwise the component will be unmounted
                         * and `resetForm` would throw an error.
                         */
                        resetForm();
                    }
                    setSubmitting(false);
                    enqueueSnackbar('Saved', { variant: 'success' });
                    // onExpand();
                }).catch((e) => {
                    setSubmitting(false);
                    setError(e);
                })
        )}
    >
        {({ isSubmitting, values, dirty }) => (
            <ExpansionPanel
                expanded={isExpanded}
                onChange={(_, expanded) => onExpand(expanded)}
                style={style}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`panel-reminder${reminder._id}-header`}
                    aria-controls={`panel-reminder${reminder._id}-content`}
                >
                    <ReminderSummary
                        reminder={reminder}
                        isUnsaved={(dirty || !reminder._id) && !isExpanded}
                    />
                </ExpansionPanelSummary>
                <Form>
                    <ExpansionPanelDetails>
                        <ReminderFormFields isEnabled={values.isEnabled} />
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <ErrorMessage error={error} />
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    size="small"
                                    onClick={() => {
                                        if (reminder._id) {
                                            setDeleteLoading(true);
                                            onDelete(reminder)
                                                .then(() => {
                                                    setDeleteLoading(false);
                                                    enqueueSnackbar('Deleted', {
                                                        variant: 'warning',
                                                    });
                                                }).catch((e) => {
                                                    setDeleteLoading(false);
                                                    setError(e);
                                                });
                                        } else {
                                            onHide(reminder);
                                        }
                                    }}
                                    danger={!!reminder._id}
                                    disabled={isSubmitting}
                                    isLoading={isDeleteLoading}
                                >
                                    {reminder._id ? 'Delete' : 'Cancel'}
                                </LoadingButton>
                                <LoadingButton
                                    type="submit"
                                    size="small"
                                    color="primary"
                                    disabled={!dirty || isDeleteLoading}
                                    isLoading={isSubmitting}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </ExpansionPanelActions>
                </Form>
            </ExpansionPanel>
        )}
    </Formik>
);

ReminderForm.propTypes = {
    reminder: ReminderPropType.isRequired,
    isExpanded: bool.isRequired,
    onExpand: func.isRequired,
    onSave: func.isRequired,
    onDelete: func.isRequired,
    onHide: func.isRequired,
    enqueueSnackbar: func.isRequired,
    isDeleteLoading: bool.isRequired,
    setDeleteLoading: func.isRequired,
    error: ErrorPropType,
    setError: func.isRequired,
    style: shape({}).isRequired,
};

ReminderForm.defaultProps = {
    error: null,
};

export default connect(
    state => ({
        isDeleteLoading: isLoadingSelector(state, 'ReminderForm'),
        error: loadingErrorSelector(state, 'ReminderForm'),
    }),
    dispatch => ({
        setDeleteLoading: isLoading => dispatch(setLoading(isLoading, 'ReminderForm')),
        setError: error => dispatch(setLoadingError(error, 'ReminderForm')),
    }),
)(withSnackbar(ReminderForm));
