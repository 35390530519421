import {
    SERVICES_REMINDERS_FIND_PENDING,
    SERVICES_REMINDERS_FIND_FULFILLED,
    SERVICES_REMINDERS_ON_CREATED,
    SERVICES_REMINDERS_ON_PATCHED,
    SERVICES_REMINDERS_ON_REMOVED,
    PREPEND_REMINDER,
    SHOW_REMINDER,
    HIDE_REMINDER, EXPAND_REMINDER,
} from './actions';
import { compareRemindersByNext } from './helpers';
import { preprocessReminder } from './preprocess';

/*
 * reducers
 */

export default function reducer(state = {
    reminders: [],
    isLoading: false,
    expanded: null,
    isInitialized: false,
}, action) {
    switch (action.type) {
        case EXPAND_REMINDER: {
            return {
                ...state,
                expanded: action.id,
            };
        }
        case SERVICES_REMINDERS_FIND_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case SERVICES_REMINDERS_FIND_FULFILLED:
            return {
                ...state,
                reminders: action.payload.data
                    .map(preprocessReminder)
                    .sort(compareRemindersByNext),
                isLoading: false,
                isInitialized: true,
            };
        case PREPEND_REMINDER:
            return {
                ...state,
                reminders: [
                    action.reminder,
                    ...state.reminders,
                ],
            };
        case SHOW_REMINDER:
            return {
                ...state,
                reminders: state.reminders.map(
                    reminder => (reminder._id !== action.reminder._id ? reminder : {
                        ...action.reminder,
                        isVisible: true,
                    }),
                ),
            };
        case HIDE_REMINDER:
            return {
                ...state,
                reminders: state.reminders.map(
                    reminder => (reminder._id !== action.reminder._id ? reminder : {
                        ...action.reminder,
                        isVisible: false,
                    }),
                ),
            };
        case SERVICES_REMINDERS_ON_CREATED:
            return {
                ...state,
                reminders: state.reminders
                    .map(reminder => (
                        (reminder._tempId && reminder._tempId === action.payload.data._tempId)
                            ? preprocessReminder(action.payload.data) : reminder
                    ))
                    .sort(compareRemindersByNext),
            };
        case SERVICES_REMINDERS_ON_PATCHED:
            return {
                ...state,
                reminders: state.reminders
                    .map(reminder => (
                        reminder._id === action.payload.data._id
                            ? preprocessReminder(action.payload.data) : reminder
                    ))
                    .sort(compareRemindersByNext),
            };
        case SERVICES_REMINDERS_ON_REMOVED:
            return {
                ...state,
                reminders: state.reminders.map(
                    reminder => (reminder._id !== action.payload.data._id ? reminder : {
                        ...reminder,
                        isVisible: false,
                    }),
                ),
            };
        default:
            return state;
    }
}
