import { getReminderGroupId } from './helpers';

export const expandedReminderSelector = state => state.reminders.expanded;

export const remindersIsLoadingSelector = state => state.reminders.isLoading;

export const remindersIsInitializedSelector = state => state.reminders.isInitialized;

export const remindersSelector = state => state.reminders.reminders;

export const groupedRemindersSelector = (state) => {
    const reminders = remindersSelector(state);
    return reminders.reduce((groups, reminder) => {
        const groupId = getReminderGroupId(reminder);
        if (groups[groupId] === undefined) {
            groups[groupId] = { // eslint-disable-line no-param-reassign
                isVisible: false,
                reminders: [],
            };
        }
        groups[groupId].reminders.push(reminder);
        if (reminder.isVisible) {
            groups[groupId].isVisible = true; // eslint-disable-line no-param-reassign
        }
        return groups;
    }, {});
};

export const blankReminderSelector = state => (
    state.reminders.reminders.find(reminder => !reminder._id)
);
