import { useEffect } from 'react';
import { bool, func, node } from 'prop-types';
import { connect } from 'react-redux';

import WithServices from '../api/ServicesProvider';
import { login as loginAction } from '../modules/auth/actions';
import { setLoading } from '../modules/loading/actions';
import { isLoadingSelector } from '../modules/loading/selectors';

const AppInitializer = ({
    authenticate, login, isInitialized, setInitialized, children,
}) => {
    // TODO: set and check isLoading to prevent simultaneous authentication
    useEffect(() => {
        if (!isInitialized) {
            authenticate()
                .then((response) => {
                    login(response.user);
                    setInitialized();
                })
                .catch((e) => {
                    console.log(e);
                    setInitialized();
                });
        }
    }, [authenticate, login, setInitialized, isInitialized]);

    return children;
};

AppInitializer.propTypes = {
    isInitialized: bool.isRequired,
    authenticate: func.isRequired,
    login: func.isRequired,
    setInitialized: func.isRequired,
    children: node.isRequired,
};

export default WithServices(connect(
    state => ({
        isInitialized: !isLoadingSelector(state, 'app'),
    }),
    (dispatch, { api }) => ({
        authenticate: api.authenticate,
        login: user => dispatch(loginAction(user)),
        setInitialized: () => dispatch(setLoading(false, 'app')),
    }),
)(AppInitializer));
