import Grid from '@material-ui/core/Grid';
import React from 'react';
import { func } from 'prop-types';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';

import { UserPropType } from '../../modules/auth/proptypes';
import { settingsSchema } from '../../modules/auth/schema';
import { setLoadingError } from '../../modules/loading/actions';
import { ErrorPropType } from '../../modules/loading/proptypes';
import { loadingErrorSelector } from '../../modules/loading/selectors';
import ErrorMessage from '../basic/ErrorMessage';
import LoadingButton from '../basic/LoadingButton';
import SettingsFormFields from './SettingsFormFields';


const SettingsForm = ({
    user, onSave, enqueueSnackbar, error, setError,
}) => (
    <Formik
        initialValues={user}
        validationSchema={settingsSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => (
            onSave(values)
                .then(() => {
                    resetForm();
                    setSubmitting(false);
                    enqueueSnackbar('Saved', { variant: 'success' });
                }).catch((e) => {
                    setSubmitting(false);
                    setError(e);
                })
        )}
    >
        {({ isSubmitting, dirty }) => (
            <Form>
                <Card>
                    <CardContent>
                        <SettingsFormFields />
                    </CardContent>
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid item>
                                <ErrorMessage error={error} />
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    type="submit"
                                    size="small"
                                    color="primary"
                                    disabled={!dirty}
                                    isLoading={isSubmitting}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Form>
        )}
    </Formik>
);

SettingsForm.propTypes = {
    user: UserPropType.isRequired,
    onSave: func.isRequired,
    enqueueSnackbar: func.isRequired,
    error: ErrorPropType,
    setError: func.isRequired,
};

SettingsForm.defaultProps = {
    error: null,
};

export default connect(
    state => ({
        error: loadingErrorSelector(state, 'SettingsForm'),
    }),
    dispatch => ({
        setError: error => dispatch(setLoadingError(error, 'SettingsForm')),
    }),
)(withSnackbar(SettingsForm));
