
export const SET_LOADING = 'loading.SET_LOADING';
export const SET_LOADING_ERROR = 'loading.SET_LOADING_ERROR';


export const setLoading = (isLoading, key) => ({
    type: SET_LOADING,
    isLoading,
    key,
});

export const setLoadingError = (error, key) => ({
    type: SET_LOADING_ERROR,
    error,
    key
});
