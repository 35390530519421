import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux';
import reduxThunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import createReducers from './reducer';

const middlewares = [];
let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger'); // eslint-disable-line global-require, import/no-extraneous-dependencies
    middlewares.push(createLogger({
        collapsed: true,
    }));
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const configureStore = (services, preloadedState) => (
    createStore(
        createReducers(services),
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                // Lets us dispatch() functions
                reduxThunk,

                // // Dispatch a promise as the value of the payload property of the action
                promise,

                ...middlewares,
            ),
        ),
    )
);

export default configureStore;
