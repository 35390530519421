import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import TextField from '../form/TextField';
import Select from '../form/Select';
import { notificationMethodChoices } from '../../modules/reminders/config';

const SettingsFormFields = () => (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md>
            <Field label="E-Mail / Login" name="email" component={TextField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md>
            <Field label="Phone" name="settings.phone" component={TextField} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md>
            <Field
                label="Default notification method"
                name="settings.notifyVia"
                component={Select}
                choices={notificationMethodChoices}
                fullWidth
            />
        </Grid>
    </Grid>
);

export default SettingsFormFields;
