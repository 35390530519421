import { func } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { UserPropType } from '../modules/auth/proptypes';
import { userSelector } from '../modules/auth/selectors';
import { loginPath } from './paths';

const PrivateRoute = ({ component: Component, user, ...rest }) => (
    <Route
        {...rest}
        render={({ location, ...props }) => (
            user !== null ? (
                <Component location={location} {...props} />
            ) : (
                <Redirect to={{ pathname: loginPath, state: { from: location } }} />
            )
        )}
    />
);

PrivateRoute.propTypes = {
    component: func.isRequired,
    user: UserPropType,
};

PrivateRoute.defaultProps = {
    user: null,
};

export default withRouter(connect(
    state => ({ user: userSelector(state) }),
)(PrivateRoute));
