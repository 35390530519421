import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import TextField from '../form/TextField';
import AlarmDateTimePickerField from '../form/AlarmDateTimePickerField';
import Switch from '../form/Switch';
import { notificationMethodChoices, recurrenceTypeChoices } from '../../modules/reminders/config';
import Select from '../form/Select';

const ReminderFormFields = ({ isEnabled }) => (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Field label="Title" name="title" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <Field label="Date" name="date" component={AlarmDateTimePickerField} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Field
                    label="Recurrence"
                    name="recurrence"
                    component={Select}
                    choices={recurrenceTypeChoices}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Field
                    label="Notify via"
                    name="notifyVia"
                    component={Select}
                    choices={notificationMethodChoices}
                    displayEmpty
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={1}>
                <Field label={isEnabled ? 'Enabled' : 'Disabled'} name="isEnabled" component={Switch} />
            </Grid>
        </Grid>
    </>
);

export default ReminderFormFields;
