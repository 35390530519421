import React from 'react';
import { node, shape } from 'prop-types';

const ServiceContext = React.createContext();

export const ServicesProvider = ({ api, services, children }) => (
    <ServiceContext.Provider
        value={{
            api,
            services,
        }}
    >
        {children}
    </ServiceContext.Provider>
);

ServicesProvider.propTypes = {
    api: shape({}).isRequired,
    services: shape({}).isRequired,
    children: node.isRequired,
};

const WithServices = (WrappedComponent) => {
    /** define inner wrapper explicitly so it doesn't show as <Unknown /> */
    const ServiceWrapper = props => (
        <ServiceContext.Consumer>
            {
                ({ api, services }) => (
                    <WrappedComponent
                        {...props}
                        api={api}
                        services={services}
                    />
                )
            }
        </ServiceContext.Consumer>
    );

    return ServiceWrapper;
};

export default WithServices;
