import { shape, string } from 'prop-types';
import React from 'react';
import BlockIcon from '@material-ui/icons/Block';
import HideFabs from '../components/fab/HideFabs';

import Metadata from '../components/layout/Metadata';

const style = {
    textAlign: 'center',
};

const Error404Page = ({ location }) => (
    <>
        <Metadata title="404" />
        <HideFabs />

        <div style={style}>
            <BlockIcon fontSize="large" />
            <h3>No match for <code>{location.pathname}</code></h3>
        </div>
    </>
);

Error404Page.propTypes = {
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
};

export default Error404Page;
