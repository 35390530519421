import {
    shape, string, instanceOf, bool,
} from 'prop-types';


export const BlankReminderPropType = shape({
    _id: string,
    _tempId: string,
    title: string,
    date: instanceOf(Date),
    next: instanceOf(Date),
    isEnabled: bool,
    notifyVia: string,
    recurrence: string,
    isVisible: bool.isRequired,
});

export const ReminderPropType = shape({
    _id: string,
    _tempId: string,
    title: string.isRequired,
    date: instanceOf(Date),
    next: instanceOf(Date),
    isEnabled: bool.isRequired,
    notifyVia: string,
    recurrence: string.isRequired,
    isVisible: bool.isRequired,
});

export const GroupedRemindersPropType = shape({});
