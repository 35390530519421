import { useEffect } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { hideFabs as hideFabsAction } from '../../modules/fabs/actions';

const HideFabs = ({ hideFabs }) => {
    useEffect(() => {
        hideFabs();
    }, [hideFabs]);

    return null;
};

HideFabs.propTypes = {
    hideFabs: func.isRequired,
};

export default connect(
    null,
    dispatch => ({
        hideFabs: () => dispatch(hideFabsAction()),
    }),
)(HideFabs);
