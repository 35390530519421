import Grow from '@material-ui/core/Grow';
import withTheme from '@material-ui/styles/withTheme';
import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

import WithServices from '../../api/ServicesProvider';
import { saveUser } from '../../modules/auth/actions';
import { userSelector } from '../../modules/auth/selectors';
import { UserPropType } from '../../modules/auth/proptypes';
import { MuiThemePropType } from '../../modules/proptypes';
import HideFabs from '../fab/HideFabs';
import SettingsForm from './SettingsForm';

const Settings = ({ user, saveSettings, theme }) => (
    <Grow in timeout={theme.transitions.duration.enteringScreen}>
        <div>
            <SettingsForm
                user={user}
                onSave={saveSettings}
            />
            <HideFabs />
        </div>
    </Grow>
);

Settings.propTypes = {
    user: UserPropType.isRequired,
    saveSettings: func.isRequired,
    theme: MuiThemePropType.isRequired,
};

export default WithServices(connect(
    state => ({
        user: userSelector(state),
    }),
    (dispatch, { services }) => ({
        saveSettings: user => dispatch(saveUser(services, user)),
    }),
)(withTheme(Settings)));
