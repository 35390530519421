import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { ErrorPropType } from '../../modules/loading/proptypes';
import { MuiClassesPropType } from '../../modules/proptypes';

const styles = theme => ({
    danger: {
        color: theme.palette.error.main,
    },
});

const ErrorMessage = ({ error, classes }) => error && (
    <Typography className={classes.danger}>
        {error.message}
    </Typography>
);

ErrorMessage.propTypes = {
    error: ErrorPropType,
    classes: MuiClassesPropType.isRequired,
};

ErrorMessage.defaultProps = {
    error: null,
};

export default withStyles(styles)(ErrorMessage);
