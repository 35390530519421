import { shape, string } from 'prop-types';

export const UserPropType = shape({
    _id: string.isRequired,
    email: string.isRequired,
    settings: shape({
        notifyVia: string.isRequired,
        phone: string,
    }).isRequired,
});
