import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { bool, node } from 'prop-types';
import { MuiClassesPropType } from '../../modules/proptypes';

const styles = theme => ({
    danger: {
        color: theme.palette.error.main,
    },
});

class LoadingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
            height: null,
        };
        this.ref = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isLoading } = this.props;

        if (!isLoading && nextProps.isLoading) {
            const { width, height } = this.ref.current.getBoundingClientRect();
            this.setState({ width, height });
        }

        return this.props !== nextProps || this.state !== nextState;
    }

    render() {
        const {
            disabled, isLoading, children, danger, classes, ...other
        } = this.props;
        const { width, height } = this.state;

        return (
            <Button
                {...other}
                disabled={disabled || isLoading}
                ref={this.ref}
                style={{ width, height }}
                classes={{ text: danger ? classes.danger : null }}
            >
                {isLoading ? (
                    <CircularProgress size={Math.min(width, height) - 10} />
                ) : children}
            </Button>
        );
    }
}

LoadingButton.propTypes = {
    disabled: bool,
    isLoading: bool,
    children: node.isRequired,
    danger: bool,
    classes: MuiClassesPropType.isRequired,
};

LoadingButton.defaultProps = {
    disabled: false,
    isLoading: false,
    danger: false,
};

export default withStyles(styles)(LoadingButton);
