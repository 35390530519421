import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Error404Page from '../pages/Error404Page';
import LoginPage from '../pages/LoginPage';
import ReminderListPage from '../pages/ReminderListPage';
import { loginPath, remindersPath, settingsPath } from './paths';
import SettingsPage from '../pages/SettingsPage';

const Routes = () => (
    <Switch>
        <Route path={loginPath} component={LoginPage} />
        <PrivateRoute exact path={remindersPath} component={ReminderListPage} />
        <PrivateRoute exact path={settingsPath} component={SettingsPage} />
        <Route component={Error404Page} />
    </Switch>
);

export default Routes;
