import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import primary from '@material-ui/core/colors/teal';
import secondary from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        // type: 'dark',
        primary,
        secondary,
    },
    overrides: {
        MuiSelect: {
            select: {
                minWidth: 60,
            },
        },
        MuiCardActions: {
            root: {
                paddingLeft: 16,
            },
        },
        MuiExpansionPanelActions: {
            root: {
                paddingLeft: 16,
            },
        },
    },
});

export default theme;
