import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { withTheme } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { showFab as showFabAction } from '../../modules/fabs/actions';
import { ADD_REMINDER_FAB } from '../../modules/fabs/types';
import { IdPropType, MuiThemePropType } from '../../modules/proptypes';
import { compareReminderGroupIds } from '../../modules/reminders/helpers';
import LoadingPage from '../basic/LoadingPage';

import ReminderForm from './ReminderForm';
import WithServices from '../../api/ServicesProvider';
import { GroupedRemindersPropType } from '../../modules/reminders/proptypes';
import {
    deleteReminder as deleteReminderAction, expandReminder,
    hideReminder as hideReminderAction,
    loadReminders as loadRemindersAction,
    saveReminder as saveReminderAction,
} from '../../modules/reminders/actions';
import {
    blankReminderSelector,
    expandedReminderSelector,
    groupedRemindersSelector, remindersIsInitializedSelector,
    remindersIsLoadingSelector,
} from '../../modules/reminders/selectors';


const ReminderList = ({
    groupedReminders, loadReminders, saveReminder, deleteReminder, hideReminder,
    isLoading, isInitialized, expanded, setExpanded, theme, showFab,
}) => {
    useEffect(() => {
        if (!isInitialized) {
            loadReminders();
        }
    }, [loadReminders, isInitialized]);

    useEffect(() => {
        if (isLoading) {
            showFab(ADD_REMINDER_FAB);
        }
    }, [isLoading, showFab]);

    const groupIds = Object.keys(groupedReminders).sort(compareReminderGroupIds);

    return (
        <LoadingPage isLoading={isLoading || !isInitialized}>
            {groupIds.map(groupId => (
                <React.Fragment key={groupId}>
                    <Grow
                        in={groupedReminders[groupId].isVisible}
                        timeout={theme.transitions.duration.complex}
                        unmountOnExit
                    >
                        <Typography color="textSecondary" variant="body2">
                            {groupId === 'disabled' && 'Inactive'}
                            {groupId === 'new' && 'New'}
                            {groupId !== 'disabled' && groupId !== 'new' && groupId}
                        </Typography>
                    </Grow>
                    <Box mb={2}>
                        {groupedReminders[groupId].reminders.map(reminder => (
                            <Grow
                                key={reminder._id || reminder._tempId}
                                in={reminder.isVisible}
                                timeout={theme.transitions.duration.complex}
                                unmountOnExit
                            >
                                <ReminderForm
                                    key={reminder._id || reminder._tempId}
                                    reminder={reminder}
                                    isExpanded={
                                        expanded === reminder._id || expanded === reminder._tempId
                                    }
                                    onExpand={isExpanded => (setExpanded(
                                        isExpanded ? (reminder._id || reminder._tempId) : null,
                                    ))}
                                    onSave={saveReminder}
                                    onDelete={deleteReminder}
                                    onHide={hideReminder}
                                />
                            </Grow>
                        ))}
                    </Box>
                </React.Fragment>
            ))}
            {groupIds.length === 0 && (
                <Typography variant="body2" color="textSecondary">
                    No reminders set up - just add one!
                </Typography>
            )}
        </LoadingPage>
    );
};

ReminderList.propTypes = {
    groupedReminders: GroupedRemindersPropType.isRequired,
    loadReminders: func.isRequired,
    saveReminder: func.isRequired,
    deleteReminder: func.isRequired,
    hideReminder: func.isRequired,
    isLoading: bool.isRequired,
    isInitialized: bool.isRequired,
    expanded: IdPropType,
    setExpanded: func.isRequired,
    theme: MuiThemePropType.isRequired,
    showFab: func.isRequired,
};

ReminderList.defaultProps = {
    expanded: null,
};

export default WithServices(connect(
    state => ({
        isLoading: remindersIsLoadingSelector(state),
        isInitialized: remindersIsInitializedSelector(state),
        groupedReminders: groupedRemindersSelector(state),
        blankReminder: blankReminderSelector(state),
        expanded: expandedReminderSelector(state),
    }),
    (dispatch, { services }) => ({
        loadReminders: () => dispatch(loadRemindersAction(services)),
        saveReminder: reminder => dispatch(saveReminderAction(services, reminder)),
        deleteReminder: reminder => dispatch(deleteReminderAction(services, reminder)),
        hideReminder: reminder => dispatch(hideReminderAction(reminder)),
        setExpanded: id => dispatch(expandReminder(id)),
        showFab: fab => dispatch(showFabAction(fab)),
    }),
)(withTheme(ReminderList)));
