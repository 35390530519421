
export default function configureRealtime(api, services, store) {
    api.service('users')
        .on('patched', user => (store.dispatch(services.users.onPatched(user))));

    api.service('reminders')
        .on('created', reminder => store.dispatch(services.reminders.onCreated(reminder)))
        .on('patched', reminder => store.dispatch(services.reminders.onPatched(reminder)))
        .on('removed', reminder => store.dispatch(services.reminders.onRemoved(reminder)));
}
