import { combineReducers } from 'redux';
import { ON_LOGOUT } from './auth/actions';

import auth from './auth/reducer';
import fabs from './fabs/reducer';
import loading from './loading/reducer';
import reminders from './reminders/reducer';

export default (services) => {
    const appReducer = combineReducers({
        remindersAPI: services.reminders.reducer,
        auth,
        fabs,
        loading,
        reminders,
    });

    return (state, action) => appReducer(action.type === ON_LOGOUT ? undefined : state, action);
};
