import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';

const DateTimePickerField = ({ field, form, ...other }) => {
    const currentError = form.errors[field.name];
    const showError = currentError && form.touched[field.name];

    return (
        <DateTimePicker
            name={field.name}
            value={field.value}
            ampm={false}
            helperText={showError ? currentError : null}
            error={showError}
            onError={(_, error) => form.setFieldError(field.name, error)}
            onChange={date => form.setFieldValue(field.name, date, true)}
            {...other}
        />
    );
};

export default DateTimePickerField;
