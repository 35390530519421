import React from 'react';
import StyledTextField from '@material-ui/core/TextField';

const TextField = ({ field, form, ...other }) => (
    <StyledTextField
        name={field.name}
        value={field.value}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched[field.name] && !!form.errors[field.name]}
        helperText={form.touched[field.name] && form.errors[field.name]}
        {...other}
    />
);

export default TextField;
