import { SHOW_FAB } from './actions';

const reducer = (state = {
    fab: null,
}, action) => {
    switch (action.type) {
        case SHOW_FAB:
            return {
                ...state,
                fab: action.fab,
            };
        default:
            return state;
    }
};

export default reducer;
