import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import featherssocketio from '@feathersjs/socketio-client';
import feathersrest from '@feathersjs/rest-client';
import authentication from '@feathersjs/authentication-client';
import reduxifyServices from 'feathers-redux';

export default function configureServices() {
    const {
        REACT_APP_APITYPE: type,
        REACT_APP_APIPROTOCOL: protocol,
        REACT_APP_APIDOMAIN: domain,
        REACT_APP_APIPORT: port,
    } = process.env;

    let api = null;
    let socket = null;
    switch (type.toLowerCase()) {
        case 'rest': {
            const rest = feathersrest(`${protocol}://${domain}:${port}`);
            api = feathers().configure(rest.fetch(window.fetch))
                .configure(authentication({
                    storage: window.localStorage,
                }));
            break;
        }
        case 'socket': {
            socket = io(`${protocol}://${domain}:${port}`, {
                transports: ['websocket'],
                forceNew: true,
            });
            api = feathers()
                .configure(featherssocketio(socket))
                .configure(authentication({
                    storage: window.localStorage,
                }));
            break;
        }
        default:
            throw new Error(`Unknown api type: ${type}`);
    }

    const services = reduxifyServices(api, {
        reminders: 'reminders',
        users: 'users',
    });

    // TODO: remove socket event listener when api data is stable
    if (process.env.NODE_ENV === 'development') {
        let wasConnected = false;
        if (socket) {
            socket
                .on('connect', () => {
                    if (wasConnected) {
                        window.location.reload();
                    }
                })
                .on('disconnect', () => {
                    wasConnected = true;
                });
        }
    }
    // ------------- end remove -------------

    return {
        socket,
        api,
        services,
    };
}
