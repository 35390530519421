import { withStyles } from '@material-ui/styles';
import React from 'react';
import { node } from 'prop-types';
import { CssBaseline, Container } from '@material-ui/core';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { isInitializedSelector } from '../../modules/loading/selectors';
import { MuiClassesPropType } from '../../modules/proptypes';
import LoadingPage from '../basic/LoadingPage';
import FabControl from '../fab/FabControl';

import Header from './Header';

/**
 * TODO: Load typeface through bundler?
 * https://material-ui.com/components/typography/#general
 */

const styles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
});

const Layout = ({ isInitialized, children, classes }) => (
    <>
        <CssBaseline />
        <Helmet>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" />
        </Helmet>

        <Header siteTitle="RemindMe" />

        <Container maxWidth="md" className={classes.container}>
            <LoadingPage isLoading={!isInitialized}>
                {children}

                <FabControl />
            </LoadingPage>
        </Container>
    </>
);

Layout.propTypes = {
    children: node.isRequired,
    classes: MuiClassesPropType.isRequired,
};

export default connect(
    state => ({
        isInitialized: isInitializedSelector(state),
    }),
)(withStyles(styles)(Layout));
