import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import StyledSwitch from '@material-ui/core/Switch';

const styles = theme => ({
    label: {
        ...theme.typography.body1,
        lineHeight: '1em',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
    },
    helperText: {
        marginTop: 2,
    },
});

const Switch = ({
    classes, field, form, label, ...other
}) => (
    <FormControl>
        {label && (
            <FormLabel component="legend" className={classes.label}>{label}</FormLabel>
        )}
        <StyledSwitch
            name={field.name}
            value={field.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            checked={!!field.value}
            color="primary"
            {...other}
        />
        {form.touched[field.name] && form.errors[field.name] && (
            <FormHelperText className={classes.helperText} error>
                {form.errors[field.name]}
            </FormHelperText>
        )}
    </FormControl>
);

export default withStyles(styles)(Switch);
