import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { node, bool } from 'prop-types';

import { MuiClassesPropType } from '../../modules/proptypes';

const styles = {
    loading: {
        minHeight: '80vh',
    },
};

const LoadingPage = ({ isLoading, classes, children }) => (
    isLoading ? (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.loading}
        >
            <Grid item xs={6} sm={4}>
                <LinearProgress />
            </Grid>
        </Grid>
    ) : children);

LoadingPage.propTypes = {
    isLoading: bool.isRequired,
    classes: MuiClassesPropType.isRequired,
    children: node.isRequired,
};

export default withStyles(styles)(LoadingPage);
