import Grow from '@material-ui/core/Grow';
import withTheme from '@material-ui/styles/withTheme';
import React from 'react';
import { MuiThemePropType } from '../../modules/proptypes';
import HideFabs from '../fab/HideFabs';
import LoginForm from './LoginForm';

const Login = ({ theme }) => {
    return (
        <Grow in timeout={theme.transitions.duration.enteringScreen}>
            <div>
                <LoginForm />
                <HideFabs />
            </div>
        </Grow>
    );
};

Login.propTypes = {
    theme: MuiThemePropType.isRequired
};

export default withTheme(Login);
