import { func } from 'prop-types';
import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { connect } from 'react-redux';
import { Card, CardActions, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import WithServices from '../../api/ServicesProvider';
import { login as loginAction } from '../../modules/auth/actions';

import { loginSchema } from '../../modules/auth/schema';
import { setLoadingError } from '../../modules/loading/actions';
import { ErrorPropType } from '../../modules/loading/proptypes';
import { loadingErrorSelector } from '../../modules/loading/selectors';
import ErrorMessage from '../basic/ErrorMessage';
import LoadingButton from '../basic/LoadingButton';
import LoginFormFields from './LoginFormFields';

const LoginForm = ({
    authenticate, login, setError, error,
}) => (
    <Formik
        initialValues={{
            email: '',
            password: '',
        }}
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting }) => (
            authenticate({
                ...values,
                strategy: 'local',
            }).then((response) => {
                setSubmitting(false);
                login(response.user);
            }).catch((e) => {
                setSubmitting(false);
                setError(e);
            })
        )}
    >
        {({ isSubmitting, setFieldValue, dirty }) => (
            <Form>
                {process.env.REACT_APP_ENV !== 'production' && (
                    <KeyboardEventHandler
                        handleKeys={['ctrl+a']}
                        onKeyEvent={() => {
                            setFieldValue('email', process.env.REACT_APP_MOCK_USERNAME);
                            setFieldValue('password', process.env.REACT_APP_MOCK_PASSWORD);
                        }}
                    />
                )}
                <Grid container justify="center" alignItems="center">
                    <Grid item sm={8}>
                        <Card>
                            <CardContent>
                                <LoginFormFields />
                            </CardContent>
                            <CardActions>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <ErrorMessage error={error} />
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            type="submit"
                                            size="small"
                                            color="primary"
                                            disabled={!dirty}
                                            isLoading={isSubmitting}
                                        >
                                            Login
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Form>
        )}
    </Formik>
);

LoginForm.propTypes = {
    authenticate: func.isRequired,
    login: func.isRequired,
    error: ErrorPropType,
    setError: func.isRequired,
};

LoginForm.defaultProps = {
    error: null,
};

export default WithServices(connect(
    state => ({
        error: loadingErrorSelector(state, 'LoginForm'),
    }),
    (dispatch, { api }) => ({
        authenticate: data => api.authenticate(data),
        login: user => dispatch(loginAction(user)),
        setError: error => dispatch(setLoadingError(error, 'LoginForm')),
    }),
)(LoginForm));
