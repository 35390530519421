import { shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Metadata from '../components/layout/Metadata';
import Login from '../components/login/Login';

import { UserPropType } from '../modules/auth/proptypes';
import { userSelector } from '../modules/auth/selectors';

const LoginPage = ({ user, location: { state } }) => {
    if (user !== null) {
        const to = state ? state.from : '/';
        return <Redirect to={to} />;
    }

    return (
        <>
            <Metadata title="Login" />
            <Login />
        </>
    );
};

LoginPage.propTypes = {
    user: UserPropType,
    location: shape({
        state: shape({
            from: shape({
                pathname: string.isRequired,
            }),
        }),
    }),
};

LoginPage.defaultProps = {
    user: null,
    location: { state: { from: { pathname: '/' } } },
};

export default connect(
    state => ({ user: userSelector(state) }),
)(LoginPage);
