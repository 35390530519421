import Grid from '@material-ui/core/Grid';
import { func, string } from 'prop-types';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import WithServices from '../../api/ServicesProvider';
import { logout as logoutAction } from '../../modules/auth/actions';
import { UserPropType } from '../../modules/auth/proptypes';
import { userSelector } from '../../modules/auth/selectors';
import { remindersPath, settingsPath } from '../../routes/paths';


const Header = ({ siteTitle, user, logout }) => (
    <AppBar position="sticky">
        <Toolbar>
            <Grid container alignItems="center" justify="space-between">
                <Grid item>
                    <Typography variant="h5" color="inherit">
                        <Link component={RouterLink} to={remindersPath} color="inherit" underline="none">
                            {siteTitle}
                        </Link>
                    </Typography>
                </Grid>
                {user && (
                    <Grid item align="center">
                        <IconButton aria-label="Settings" component={RouterLink} to={settingsPath} color="inherit">
                            <SettingsIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="Logout" color="inherit" onClick={logout}>
                            <LogoutIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </Toolbar>
    </AppBar>
);

Header.propTypes = {
    siteTitle: string,
    user: UserPropType,
    logout: func.isRequired,
};

Header.defaultProps = {
    siteTitle: '',
    user: null,
};

export default WithServices(connect(
    state => ({
        user: userSelector(state),
    }),
    (dispatch, { api }) => ({
        logout: () => {
            api.logout();
            dispatch(logoutAction());
        },
    }),
)(Header));
