import React from 'react';
import Metadata from '../components/layout/Metadata';
import Settings from '../components/settings/Settings';

const SettingsPage = () => (
    <>
        <Metadata title="Settings" />
        <Settings />
    </>
);

export default SettingsPage;
