import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './styles/App.css';
import AppInitializer from './components/AppInitializer';
import SnackbarProvider from './components/basic/SnackbarProvider';
import Layout from './components/layout/Layout';
import configureStore from './modules/configureStore';
import configureServices from './api/configureServices';
import configureRealtime from './api/configureRealtime';
import { ServicesProvider } from './api/ServicesProvider';
import Routes from './routes/Routes';
import theme from './styles/theme';

const { api, services } = configureServices();
const store = configureStore(services);
configureRealtime(api, services, store);

function App() {
    return (
        <Provider store={store}>
            <ServicesProvider api={api} services={services}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider>
                            <AppInitializer>
                                <Router>
                                    <Layout>
                                        <Routes />
                                    </Layout>
                                </Router>
                            </AppInitializer>
                        </SnackbarProvider>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </ServicesProvider>
        </Provider>
    );
}

export default App;
