import React from 'react';
import {
    Select as StyledSelect,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';

const Select = ({
    field, form, label, choices, displayEmpty, emptyLabel, autoWidth, fullWidth, ...other
}) => (
    <FormControl fullWidth={fullWidth}>
        <InputLabel shrink={displayEmpty} htmlFor={field.name}>{label}</InputLabel>
        <StyledSelect
            name={field.name}
            value={field.value || ''}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched[field.name] && !!form.errors[field.name]}
            displayEmpty={displayEmpty}
            autoWidth={autoWidth}
            {...other}
        >
            {displayEmpty && (
                <MenuItem value="">
                    <em>{emptyLabel || 'Default'}</em>
                </MenuItem>
            )}
            {choices.map(({ label: choiceLabel, value }) => (
                <MenuItem value={value} key={value}>
                    {choiceLabel}
                </MenuItem>
            ))}
        </StyledSelect>
        {form.touched[field.name] && form.errors[field.name] && (
            <FormHelperText>{form.errors[field.name]}</FormHelperText>
        )}
    </FormControl>
);

export default Select;
