import {
    ON_LOGIN_SUCCESS, SERVICES_USERS_ON_PATCHED,
} from './actions';
import { preprocessUser } from './preprocess';

/*
 * reducers
 */

export default function reducer(state = {
    user: null,
}, action) {
    switch (action.type) {
        case ON_LOGIN_SUCCESS:
            return {
                ...state,
                user: preprocessUser(action.user),
            };
        case SERVICES_USERS_ON_PATCHED:
            return {
                ...state,
                user: state.user._id === action.payload.data._id ? action.payload.data : state.user,
            };
        default:
            return state;
    }
}
