import { withTheme } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { FabPropType } from '../../modules/fabs/proptypes';
import { fabSelector } from '../../modules/fabs/selectors';
import { ADD_REMINDER_FAB } from '../../modules/fabs/types';
import { MuiThemePropType } from '../../modules/proptypes';
import AddReminderFab from './AddReminderFab';

const FabControl = ({ fab, theme }) => (
    <>
        <AddReminderFab
            isVisible={fab === ADD_REMINDER_FAB}
            timeout={{
                enter: theme.transitions.duration.enteringScreen,
                exit: theme.transitions.duration.leavingScreen,
            }}
        />
    </>
);

FabControl.propTypes = {
    theme: MuiThemePropType.isRequired,
    fab: FabPropType,
};

FabControl.defaultProps = {
    fab: null,
};

export default connect(
    state => ({
        fab: fabSelector(state),
    }),
)(withTheme(FabControl));
