import { getBlankReminder } from './config';
import { preprocessReminder } from './preprocess';
import { blankReminderSelector } from './selectors';

export const SERVICES_REMINDERS_FIND_PENDING = 'SERVICES_REMINDERS_FIND_PENDING';
export const SERVICES_REMINDERS_FIND_FULFILLED = 'SERVICES_REMINDERS_FIND_FULFILLED';
export const SERVICES_REMINDERS_ON_CREATED = 'SERVICES_REMINDERS_ON_CREATED';
export const SERVICES_REMINDERS_ON_PATCHED = 'SERVICES_REMINDERS_ON_PATCHED';
export const SERVICES_REMINDERS_ON_REMOVED = 'SERVICES_REMINDERS_ON_REMOVED';

export const PREPEND_REMINDER = 'reminder.PREPEND_REMINDER';
export const SHOW_REMINDER = 'reminder.SHOW_REMINDER';
export const HIDE_REMINDER = 'reminder.HIDE_REMINDER';
export const EXPAND_REMINDER = 'reminder.EXPAND_REMINDER';

/*
 * action creators
 */

export const prependReminder = reminder => ({
    type: PREPEND_REMINDER,
    reminder,
});

export const showReminder = reminder => ({
    type: SHOW_REMINDER,
    reminder,
});

export const hideReminder = reminder => ({
    type: HIDE_REMINDER,
    reminder,
});

export const expandReminder = id => ({
    type: EXPAND_REMINDER,
    id,
});

export const addBlankReminder = () => (dispatch, getState) => {
    const existing = blankReminderSelector(getState());
    if (existing) {
        dispatch(showReminder(existing));
        return existing._tempId;
    }

    const reminder = getBlankReminder();
    dispatch(prependReminder(reminder));
    return reminder._tempId;
};

export const loadReminders = services => (
    dispatch => dispatch(services.reminders.find())
);

export const saveReminder = (services, reminder) => (
    dispatch => (reminder._id
        ? dispatch(services.reminders.patch(reminder._id, reminder))
        : dispatch(services.reminders.create(reminder))
    ).then(({ value }) => preprocessReminder(value))
);

export const deleteReminder = (services, reminder) => dispatch => (
    dispatch(services.reminders.remove(reminder._id))
);
