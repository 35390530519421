import compareAsc from 'date-fns/compareAsc';

export const compareRemindersByNext = (r1, r2) => {
    if (r1.isEnabled && !r2.isEnabled) {
        return -1;
    }

    if (!r1.isEnabled && r2.isEnabled) {
        return 1;
    }

    if (r1.next === null && r2.next === null) {
        return compareAsc(r1.date, r2.date) || r1.title.localeCompare(r2.title);
    }

    if (r1.next && r2.next) {
        return compareAsc(r1.next, r2.next) || r1.title.localeCompare(r2.title);
    }

    if (r1.next) {
        return -1;
    }

    return 1;
};

export const compareReminderGroupIds = (g1, g2) => {
    if (g1 === 'new') {
        return -1;
    }
    if (g2 === 'new') {
        return 1;
    }
    return g1.localeCompare(g2);
};

export const getReminderGroupId = (reminder) => {
    if (!reminder._id) {
        return 'new';
    }

    if (reminder.isEnabled && reminder.next) {
        return reminder.next.getFullYear();
    }

    return 'disabled';
};
