import { Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'formik';

import TextField from '../form/TextField';

const LoginFormFields = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Field label="E-Mail" name="email" component={TextField} fullWidth />
        </Grid>
        <Grid item xs={12}>
            <Field label="Password" name="password" type="password" component={TextField} fullWidth />
        </Grid>
    </Grid>
);

LoginFormFields.propTypes = {};

export default LoginFormFields;
