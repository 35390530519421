import { Fab } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import {
    bool, func, number, shape,
} from 'prop-types';
import AlarmIcon from '@material-ui/icons/AlarmAdd';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import React from 'react';
import { MuiClassesPropType } from '../../modules/proptypes';
import { BlankReminderPropType } from '../../modules/reminders/proptypes';
import { blankReminderSelector } from '../../modules/reminders/selectors';
import { addBlankReminder as addBlankReminderAction, expandReminder } from '../../modules/reminders/actions';

const styles = theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(4),
    },
});

const AddReminderFab = ({
    isVisible, classes, blankReminder, addBlankReminder, setExpanded, timeout,
}) => (
    <Zoom
        in={isVisible && (!blankReminder || !blankReminder.isVisible)}
        timeout={timeout}
        style={{
            transitionDelay: `${isVisible ? timeout.exit : 0}ms`,
        }}
        unmountOnExit
    >
        <Fab
            color="secondary"
            aria-label="Add"
            className={classes.fab}
            onClick={() => {
                const _tempId = addBlankReminder();
                setExpanded(_tempId);
            }}
        >
            <AlarmIcon />
        </Fab>
    </Zoom>
);

AddReminderFab.propTypes = {
    isVisible: bool.isRequired,
    classes: MuiClassesPropType.isRequired,
    blankReminder: BlankReminderPropType,
    addBlankReminder: func.isRequired,
    setExpanded: func.isRequired,
    timeout: shape({ enter: number, exit: number }).isRequired,
};

AddReminderFab.defaultProps = {
    blankReminder: null,
};

export default connect(
    state => ({
        blankReminder: blankReminderSelector(state),
    }),
    dispatch => ({
        addBlankReminder: () => dispatch(addBlankReminderAction()),
        setExpanded: id => dispatch(expandReminder(id)),
    }),
)(withStyles(styles)(AddReminderFab));
