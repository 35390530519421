import React from 'react';
import Metadata from '../components/layout/Metadata';
import ReminderList from '../components/reminder/ReminderList';

const ReminderListPage = () => (
    <>
        <Metadata title="Home" />
        <ReminderList />
    </>
);

export default ReminderListPage;
