import { SET_LOADING, SET_LOADING_ERROR } from './actions';


const reducer = (state = {
    app: { isLoading: true, error: null },
}, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                [action.key]: {
                    isLoading: action.isLoading,
                    error: null,
                },
            };
        case SET_LOADING_ERROR:
            return {
                ...state,
                [action.key]: {
                    isLoading: false,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

export default reducer;
