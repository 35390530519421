import React from 'react';
import { bool } from 'prop-types';
import { Typography, Grid, Badge } from '@material-ui/core';
import { format } from 'date-fns';

import { ReminderPropType } from '../../modules/reminders/proptypes';

const ReminderSummary = ({ reminder, isUnsaved }) => (
    <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item>
            <Badge color="secondary" variant="dot" invisible={!isUnsaved}>
                <Typography
                    variant="body1"
                    color={(reminder.isEnabled && reminder.next) ? 'textPrimary' : 'textSecondary'}
                >
                    {reminder.title || 'New Reminder'}
                </Typography>
            </Badge>
        </Grid>
        <Grid item>
            <Typography
                variant="body2"
                color={(reminder.isEnabled && reminder.next) ? 'textPrimary' : 'textSecondary'}
            >
                {reminder._id && format(reminder.next || reminder.date, 'MMMM do HH:mm')}
            </Typography>
        </Grid>
    </Grid>
);

ReminderSummary.propTypes = {
    reminder: ReminderPropType.isRequired,
    isUnsaved: bool,
};

ReminderSummary.defaultProps = {
    isUnsaved: false,
};

export default ReminderSummary;
