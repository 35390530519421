import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/AddAlarm';

import DateTimePickerField from './DateTimePickerField';

const AlarmDateTimePickerField = props => (
    <DateTimePickerField
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                        <AlarmIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export default AlarmDateTimePickerField;
